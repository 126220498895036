import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import OrderProcessArchiveFilter from "../components/Blog/OrderProcessArchiveFilter"
import Breadcrumbs from "src/utils/Breadcrumbs"
import GatsbyLink from "src/utils/GatsbyLink"
import BlockThreeColumns from "src/components/Blocks/BlockThreeColumns"
import BlockCta from "src/components/Blocks/BlockCta"

const ArchivePostItem = props => {
  return (
    <Link
      to={`/our-blog/${props.uid}/`}
      className={`group col-span-2 text-left border-l border-gold mb-12`}
      title={`${props.data.title.text}`}
    >
      <Fade>
        <Img
          fluid={props.data.featured_image.fluid}
          alt={props.data.title.text}
          className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 max-w-full h-0 pb-[60%]"
        />
        <div className="p-6 pb-0">
          <date className="text-darkgreysecond text-xs inline-block tracking-normal">
            {props.data.post_date} - {props.tags.join(", ")}
          </date>
          <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase my-6 group-hover:text-gold duration-500 ease-in-out">
            {props.data.title.text}
          </h2>
            <div className={`description line-clamp-3`}>
            <RichText
                render={props.data.description.richText}
                serializeHyperlink={GatsbyLink}
            />
            </div>
        </div>
      </Fade>
    </Link>
  )
}

const ArchivePostsContainer = props => {
  return (
    <div className="container pt-12 blog-items">
      <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-6">
        {props.data.map((post, index) => {
          return (
            <ArchivePostItem
              {...post.node}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}

const ArchiveBlogPost = props => {
  const crumbs = [
    { label: "Advice", href: "/advice" },
    { label: "Order Process", href: `/order-process/${props.pageContext.stageSlug}`, active: true },
  ]

  const threeColumns = props.data.prismicPage.data.body.filter(item => { return Object.keys(item).length !== 0 })[0]

  const modifiedJourneyTitle =
    props.data.prismicStage.data?.journey_title?.richText[0].text
    ?.replace(/Step \d+: /g, "")
    .replace("‘outline’", "Outline")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

  return (
    <article className="order-process">
      <Seo title={`${modifiedJourneyTitle} | Order Process`} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <main className={`bg-offwhite`}>
        <div className="container text-center py-12">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              <RichText
                render={props.data.prismicStage.data?.journey_title?.richText}
                serializeHyperlink={GatsbyLink}
              />
            </h2>          
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-prose mx-auto mt-10">
              <RichText
                render={props.data.prismicStage.data?.journey_description?.richText}
                serializeHyperlink={GatsbyLink}
              />
            </p>
          </Fade>
        </div>
        <OrderProcessArchiveFilter activeSlug={props.pageContext.stageSlug} />
        <div className="container flex flex-wrap md:flex-nowrap items-center gap-12">
          <Fade>
            <div className="w-full md:w-1/2 order-2 md:order-1">
                <Img fluid={props.data.prismicStage.data.featured_image.fluid} alt={props.data.prismicStage.data.title.text} />
            </div>
            <div className="w-full md:w-1/2 md:px-12 order-1 md:order-2">
                <h2 className="font-display text-gold rfs:text-5xl">{props.data.prismicStage.data.title.text}</h2>
                <div className="w-12 separator mt-5"></div>
                <div className="mt-10">
                    <RichText
                        render={props.data.prismicStage.data.description.richText}
                        serializeHyperlink={GatsbyLink}
                    />                
                </div>
            </div>
          </Fade>
        </div>
        {props.pageContext.stageSlug === 'its-complete' &&
          <div className="bg-verylightgrey">
            <div className="container items-center flex flex-col">
              <h2 className="font-display text-gold rfs:text-5xl text-center max-w-sm mx-auto">Get your details right and speak to us early</h2>
              <div className="w-12 separator mt-5"></div>
              <p className="mt-10 text-[13px] text-darkgrey max-w-sm text-center">If you’ve got plans or are ready to start on a self-build project, get in touch with us to see how we can help you on your journey.</p>
              <Link to="/contact/" className="btn dakrgrey mt-8">Contact the team</Link>
            </div>
          </div>
        }
        <div className="bg-darkgrey">
          <div className="container flex md:flex-row flex-col items-start justify-center gap-12 md:divide-x md:divide-y-0 divide-y divide-[#505050]">
            <Fade>
              <div className="w-full md:w-1/2">
                <div className="md:max-w-[400px] md:mr-auto">
                  <h2 className="font-display text-gold rfs:text-5xl">{props.data.prismicStage.data.dark_block_title_left.text}</h2>
                  <div className="w-12 separator mt-5"></div>
                  <div className="mt-6 text-white">
                    <RichText
                        render={props.data.prismicStage.data.dark_block_content_left.richText}
                        serializeHyperlink={GatsbyLink}
                    />
                    <ul>
                      {props.data.prismicStage.data.dark_block_ticks_left?.map((item) => (
                        <li className="flex flex-row items-center justify-start mb-3 text-sm">
                          {item.dark_block_ticks_right_title?.text &&
                            <>
                              <img className="mr-2 w-[18px]" src="https://deuren.cdn.prismic.io/deuren/9fa32df9-fccb-41ba-9d34-e55d9decc3cb_tick.svg" alt="Deuren tick"/>
                              {item.dark_block_ticks_right_title?.text}
                            </>
                          }
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 md:pl-12 md:pt-0 pt-12">
                <div className="md:max-w-[400px] md:ml-auto">
                  <h2 className="font-display text-gold rfs:text-5xl">{props.data.prismicStage.data.dark_block_title_right.text}</h2>
                  <div className="w-12 separator mt-5"></div>
                  <div className="mt-6 text-white">
                    <RichText
                        render={props.data.prismicStage.data.dark_block_content_right.richText}
                        serializeHyperlink={GatsbyLink}
                    />                
                    <ul>
                      {props.data.prismicStage.data.dark_block_ticks_right.map((item) => (
                        <li className="flex flex-row items-center justify-start mb-3 text-sm">
                          <img className="mr-2 w-[18px]" src="https://deuren.cdn.prismic.io/deuren/9fa32df9-fccb-41ba-9d34-e55d9decc3cb_tick.svg" alt="Deuren tick" />
                          {item.dark_block_ticks_right_title.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Fade>
          </div>  
        </div>
        <div className="flex flex-col items-center pt-24">
            <h2 className="font-display text-gold rfs:text-5xl text-center">Resources &amp; advice</h2>
            <div className="w-12 separator mt-5"></div>
        </div>      
        <ArchivePostsContainer
          data={props.data.allPrismicBlogPost.edges}
          categorySlug={props.pageContext.stageSlug}
        />
        {props.pageContext.stageSlug !== 'its-complete' &&
          <div className="bg-darkgrey">
              <div className="container items-center flex flex-col">
                  <h2 className="font-display text-gold rfs:text-5xl text-center max-w-sm mx-auto">Get your details right and speak to us early</h2>
                  <div className="w-12 separator mt-5"></div>
                  <p className="mt-10 text-[13px] text-white max-w-sm text-center">If you’ve got plans or are ready to start on a self-build project, get in touch with us to see how we can help you on your journey.</p>
                  <Link to="/contact/" className="btn white mt-8">Contact the team</Link>
              </div>
          </div>
        }
        <div className="pb-24">
            <BlockThreeColumns data={threeColumns} />
        </div>
        <BlockCta />
      </main>
    </article>
  )
}

export default ArchiveBlogPost

export const ArchiveStageQuery = graphql`
  query ArchiveStageBlogPostQuery(
    $stage: String = "//",
    $stageSlug: String!
  ) {
    allPrismicBlogPost(
      sort: { fields: data___post_date, order: DESC }
      filter:{ data:{ stages:{ elemMatch:{ stage:{ uid:{ regex: $stage }}}}}}
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            post_date(formatString: "DD MMM YYYY")
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              richText
            }
          }
        }
      }
    }
    prismicStage (uid: { eq: $stageSlug }) {
        data {
            title {
                text
            }
            description {
                richText
            }
            journey_title {
                richText
            }
            journey_description {
                richText
            }
            featured_image {
                fluid(maxWidth: 800) {
                    ...GatsbyPrismicImageFluid
                }
            }
            dark_block_title_left {
              text
            }
            dark_block_content_left {
                richText
            }
            dark_block_ticks_left {
              dark_block_ticks_left_title {
                text
              }
            }
            dark_block_title_right {
              text
            }
            dark_block_content_right {
                richText
            }
            dark_block_ticks_right {
              dark_block_ticks_right_title {
                text
              }
            }
        }
    }    
    prismicPage(uid: { eq: "homepage" }) {
        data {
            body {
            ... on PrismicPageDataBodyThreeColumns {
                slice_type
                items {
                  column_link {
                    slug
                    uid
                    type
                    document {
                      ... on PrismicPage {
                        id
                        data {
                          custom_slug
                          custom_parent
                          parent {
                            document {
                              ... on PrismicPage {
                                uid
                                data {
                                  custom_slug
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  column_text {
                    text
                    html
                    richText
                  }
                  image {
                    url
                    alt
                    fluid(srcSetBreakpoints: [500, 1000]) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  video_link {
                    text
                  }
                  title {
                    text
                  }
                  plain_column_link {
                    text
                  }
                  image_is_icon
                }
                primary {
                  background_colour
                  center_content
                  remove_bottom_padding
                  alternative_titles_style
                }
              }        
            }    
        }
    }
  }
`

import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Fade from "react-reveal/Fade"

const BlogArchiveFilter = ({ activeSlug }) => {

  const [show, setShow] = useState(false)

  const data = useStaticQuery(graphql`
    query OrderProcessArchiveFilterQuery {
        allPrismicStage(sort: {fields: data___order, order: ASC}) {
            edges {
                node {
                    uid
                    data {
                      title {
                        text
                      }
                    }
                }
            }
        }
    }
  `)

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <>
      <div className="w-full bg-darkgrey py-6">
        <div className="container py-0">
          <Fade>
              <div className="relative w-full m-auto flex flex-row justify-between">
                {data.allPrismicStage.edges.map((item, index) => {
                  const slug = item.node.uid
                  return (
                    <Link
                      to={`/order-process/${slug}/`}
                      className="font-bold text-[11px] text-white text-center flex flex-col capitalize bg-darkgrey z-10 w-auto md:w-32"
                      activeClassName="active"
                    >
                    <span
                      className={`mx-auto w-6 h-6 text-xs text-center rounded-full border font-bold inline-flex items-center justify-center md:mb-2 transition duration-300 border-gold ${
                        activeSlug === item.node.uid
                          ? " bg-gold text-white"
                          : "bg-darkgrey text-white"
                      }`}
                    >{ index + 1 }</span>  
                    <span className="hidden md:block">
                      {item.node.uid.replaceAll('-',' ').replaceAll('its',"it's")}
                    </span>     
                    </Link>
                  )
                })}
                <hr className="h-px w-full border-gold absolute top-1/2 z-0" />
              </div>
          </Fade>
        </div>
      </div>
      <div className="md:hidden">
          <button className="bg-verylightgrey justify-center w-full py-5 uppercase text-[12px] font-body tracking-[1.44px] text-center font-bold" onClick={handleClick}>Choose your stage <i className="far fa-plus text-[10px] transform -translate-y-px"></i></button>
      </div>
      { show && (
        <div className="md:hidden fixed top-0 w-full h-screen bg-white z-[2000000001]">
          <div className="flex items-center">
            <div className="px-6">
              <div className="uppercase text-[12px] font-body tracking-[1.44px] leading-[12px] text-center font-bold relative after:absolute after:h-px after:w-4 after:bg-darkgrey after:-bottom-3 after:left-0">Choose your stage</div>
            </div>
            <button className="ml-auto p-6 leading-[10px]" onClick={handleClick} aria-label="close"><i className="fal fa-times fa-lg"></i></button>
          </div>
          <div className="p-6">
            {data.allPrismicStage.edges.map((item, index) => {
              const slug = item.node.uid
              return (
                <Link
                  to={`/order-process/${slug}/`}
                  className={`block font-bold text-[12px] leading-[15px] z-10 w-full my-4 ${activeSlug === item.node.uid ? "text-gold" : "text-darkgrey"}`}
                  activeClassName="active"
                >
                <span
                  className={`mx-auto w-7 h-7 text-xs rounded-full border font-bold inline-flex items-center justify-center py-2 transition duration-300 mr-2 ${
                    activeSlug === item.node.uid
                      ? "border-gold bg-gold text-white"
                      : "border-darkgrey text-darkgrey"
                  }`}
                >{ index + 1 }</span>  
                <span>
                  {item.node.data.title.text.split(":")[1]}
                </span>
                </Link>
              )
            })}
          </div>
        </div>
      ) }
    </>
  )
}

export default BlogArchiveFilter
